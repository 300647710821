import React, { useState } from 'react'
import { apartments } from '../../../utils/StaticData'

interface MoreApartmentsSectionProps {
    lang: any
}

const MoreApartmentsSection = ({ lang }: MoreApartmentsSectionProps) => {
    const [showMore, setShowMore] = useState({
        west: false,
        north: false,
        center: false,
        east: false,
        south: false,
        tangerang: false,
    })

    return (

        <div className="py-12 flex flex-row text-gray-73 text-sm container lg:px-4 grid grid-cols-6 gap-4">
            <div className="flex flex-col">
                <p className="font-bold mb-4">
                    {lang.jakbar_apart}
                </p>
                {
                    apartments.west.map(({ url, title }, idx) =>
                        <a key={idx} href={url} className={`${(idx >= 8) && !showMore.west ? 'hidden' : ''} mb-1 ellipsis-one-line hover:text-main`}>{title}</a>
                    )
                }
                {
                    !showMore.west ?
                        <p onClick={() => setShowMore(prev => ({ ...prev, west: true }))} className="underline cursor-pointer hover:text-main">{lang.more}...</p>
                        : null
                }
            </div>
            <div className="flex flex-col">
                <p className="font-bold mb-4">
                    {lang.jakut_apart}
                </p>
                {
                    apartments.north.map(({ url, title }, idx) =>
                        <a key={idx} href={url} className={`${(idx >= 8) && !showMore.north ? 'hidden' : ''} mb-1 ellipsis-one-line hover:text-main`}>{title}</a>
                    )
                }
                {
                    !showMore.north ?
                        <p onClick={() => setShowMore(prev => ({ ...prev, north: true }))} className="underline cursor-pointer hover:text-main">{lang.more}...</p>
                        : null
                }
            </div>
            <div className="flex flex-col">
                <p className="font-bold mb-4">
                    {lang.jakpus_apart}
                </p>
                {
                    apartments.center.map(({ url, title }, idx) =>
                        <a key={idx} href={url} className={`${(idx >= 8) && !showMore.center ? 'hidden' : ''} mb-1 ellipsis-one-line hover:text-main`}>{title}</a>
                    )
                }
                {
                    !showMore.center ?
                        <p onClick={() => setShowMore(prev => ({ ...prev, center: true }))} className="underline cursor-pointer hover:text-main">{lang.more}...</p>
                        : null
                }
            </div>
            <div className="flex flex-col">
                <p className="font-bold mb-4">
                    {lang.jaktim_apart}
                </p>
                {
                    apartments.east.map(({ url, title }, idx) =>
                        <a key={idx} href={url} className={`${(idx >= 8) && !showMore.east ? 'hidden' : ''} mb-1 ellipsis-one-line hover:text-main`}>{title}</a>
                    )
                }
                {
                    !showMore.east ?
                        <p onClick={() => setShowMore(prev => ({ ...prev, east: true }))} className="underline cursor-pointer hover:text-main">{lang.more}...</p>
                        : null
                }
            </div>
            <div className="flex flex-col">
                <p className="font-bold mb-4">
                    {lang.jaksel_apart}
                </p>
                {
                    apartments.south.map(({ url, title }, idx) =>
                        <a key={idx} href={url} className={`${(idx >= 8) && !showMore.south ? 'hidden' : ''} mb-1 ellipsis-one-line hover:text-main`}>{title}</a>
                    )
                }
                {
                    !showMore.south ?
                        <p onClick={() => setShowMore(prev => ({ ...prev, south: true }))} className="underline cursor-pointer hover:text-main">{lang.more}...</p>
                        : null
                }
            </div>
            <div className="flex flex-col">
                <p className="font-bold mb-4">
                    {lang.tangerang_apart}
                </p>
                {
                    apartments.tangerang.map(({ url, title }, idx) =>
                        <a key={idx} href={url} className={`${(idx >= 8) && !showMore.tangerang ? 'hidden' : ''} mb-1 ellipsis-one-line hover:text-main`}>{title}</a>
                    )
                }
                {
                    !showMore.tangerang ?
                        <p onClick={() => setShowMore(prev => ({ ...prev, tangerang: true }))} className="underline cursor-pointer hover:text-main">{lang.more}...</p>
                        : null
                }
            </div>
        </div>
    )
}

export default MoreApartmentsSection
